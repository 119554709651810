'use client';

import React, { PropsWithChildren } from 'react';
import theme from '@grimme/theme';
import { CssBaseline, ThemeProvider, Typography } from '@mui/material';

export default function MUIProvider(props: PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* h-full is needed here so on pages we can set non-fixed heights on components */}
      <Typography className="h-full" component="div">
        {props.children}
      </Typography>
    </ThemeProvider>
  );
}
